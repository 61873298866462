import { NavBarComponent, NavBarProps, SelectedOptions, SetOptions } from './NavBar';
import { useTheme } from '@emotion/react';
import React from 'react';
import { TripTypes } from '@model/common';
import optionsProps from './optionsProps';

export interface NavBarContainerProps {
  showFiltersBarDrawer?: boolean;
  renderSearch?: JSX.Element;
  testId?: string;
  isOverlay?: boolean;
  promoBannerTripType?: TripTypes;
}

export const NavBarContainer = (ownProps: NavBarContainerProps) => {
  const selectedOptions: SelectedOptions = optionsProps().selectedOptions;
  const setOptions: SetOptions = optionsProps().setOptions;

  const props: NavBarProps = {
    theme: useTheme(),
    selectedOptions,
    setOptions,
    ...ownProps
  };

  return <NavBarComponent {...props} />;
};
