import { Page } from './page';

export const disallowedPages = [
  Page.VIDEO_GUIDES,
  Page.TRAVEL_GUIDES,
  Page.TRAVEL_DISCLAIMER_FORM,
  Page.SITEMAP,
  Page.GUESTS,
  Page.PAYMENT,
  Page.PAYMENT_ADYEN,
  Page.CONFIRMATION,
  Page.CONFIRMATION_ADYEN,
  Page.MY_BOOKING,
  Page.AGENTS
];

export const generalPages = [Page.HOME, Page.HELP, Page.HELP_CENTRE];

export const holidayPages = [
  Page.SEARCH,
  Page.FLIGHTS,
  Page.REVIEW,
  Page.PRODUCT,
  Page.HOTELS,
  Page.RESORTS,
  Page.OFFERS
];

export const tourPages = [Page.TOURS_SEARCH, Page.TOURS_EXTRAS, Page.TOURS_PRODUCT, Page.ALL_TOURS];
