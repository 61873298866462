import { SortOptions } from '@model/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedAirport,
  getSelectedMonth,
  getSelectedSortOption,
  getTripTypeFilter,
  setSelectedAirport,
  setSelectedMonth,
  setSelectedSortOption,
  setTripTypeFilter
} from '@state/navigation';
import { TripTypes } from '@model/contentful';

export default function optionsProps() {
  const dispatch = useDispatch();

  const selectedOptions = {
    selectedAirport: useSelector(getSelectedAirport),
    selectedMonth: useSelector(getSelectedMonth),
    selectedSortOption: useSelector(getSelectedSortOption),
    selectedFilter: useSelector(getTripTypeFilter)
  };

  const setOptions = {
    setSelectedAirport: (payload: string) => dispatch(setSelectedAirport(payload)),
    setSelectedFilter: (payload: TripTypes) => dispatch(setTripTypeFilter(payload)),
    setSelectedMonth: (payload: string) => dispatch(setSelectedMonth(payload)),
    setSelectedSortOption: (payload: SortOptions) => dispatch(setSelectedSortOption(payload))
  };

  return { selectedOptions, setOptions };
}
