import React from 'react';
import styled from '@emotion/styled';
import { SortDirection, SortOptions } from '@model/common';
import { mixins } from '@styles/mixins';
import { sortByField } from '@util/common';
import { formatFlightTime } from '@util/date-time';
import { withTheme } from '@emotion/react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps, SelectableThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { DestinationLink } from '@components/common/next-link';
import { CMSDestinations, CMSDestination, CMSTripType } from '@model/contentful/destination';
import { TripTypes } from '@model/contentful';
import { getTripTypesFromDestinations, getDestinationsByTripType } from '@util/contentful';
import { SelectedOptions, SetOptions } from './NavBar';
import { CountryFlag } from '@components/common/country-flag/CountryFlag';
import { tripTypeLabels } from '@model/common/tours/trip-types';
import { TripTypesLink } from '@components/trip-types/TripTypesLink';
import { Button, ButtonSize } from '@components/material-ui';

/* ***************** *
 *       Types       *
 * ***************** */
export interface DestinationsNavContentProps {
  theme?: Theme;
  testId?: string;
  selectedOptions: SelectedOptions;
  setOptions: SetOptions;
  onToggleDesktopNav?: () => void;
  cmsDestinations: CMSDestinations;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const DestinationsContainer = styled.div({
  display: 'flex',
  flex: 1
});
const FiltersColumn = styled.div(({ theme }: ThemeProps) => ({
  width: 220,
  padding: theme.custom.spacing.xLarge,
  paddingTop: 74,
  backgroundColor: theme.custom.colors.group10.lighter
}));

const FiltersHeading = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  color: theme.custom.colors.group4.base,
  marginTop: 0,
  marginBottom: 61,
  ...mixins(theme).truncate
}));

const FilterItemsWrapper = styled.ul({
  listStyle: 'none',
  padding: 0
});

const FilterItem: any = styled.li(({ theme, isSelected }: SelectableThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  ...(isSelected && { color: theme.custom.colors.group1.base }),
  cursor: isSelected ? 'default' : 'pointer',
  marginBottom: theme.custom.spacing.medium,

  ['&:hover']: {
    color: isSelected ? theme.custom.colors.group1.base : theme.custom.colors.group1.base
  }
}));

const DestinationsContentContainer = styled.div(({ theme }: ThemeProps) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 95,
  paddingTop: 55,
  transition: `padding ${theme.custom.transitions.smooth}`,

  [`@media (max-width: ${theme.custom.contentSizes.maxContentWidth}px)`]: {
    paddingLeft: 55
  }
}));

const DestinationsSortBar = styled.div(({ theme }: ThemeProps) => ({
  borderBottom: `1px solid ${theme.custom.colors.group10.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: theme.custom.spacing.medium,
  marginTop: 8,

  [`@media (max-width: ${theme.custom.contentSizes.maxContentWidth}px)`]: {
    marginRight: theme.custom.spacing.medium
  }
}));

const StyledButton = styled(Button)(({ theme }: ThemeProps) => ({
  ['.MuiButtonBase-root']: {
    borderRadius: 6,
    padding: `0 ${theme.custom.spacing.medium}px`
  }
}));

const Sort = styled.div({
  display: 'flex'
});

const SortHeading = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  ...mixins(theme).truncate,
  margin: 0
}));

const SortOptionsContainer = styled.ul(({ theme }: ThemeProps) => ({
  listStyle: 'none',
  padding: 0,
  paddingLeft: theme.custom.spacing.large,
  display: 'flex',
  flexWrap: 'nowrap'
}));

const SortOption: any = styled.li(({ theme, isSelected }: SelectableThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  ...(isSelected && { color: theme.custom.colors.group1.base }),
  ...mixins(theme).truncate,
  lineHeight: '3rem',
  paddingRight: theme.custom.spacing.large,
  cursor: isSelected ? 'default' : 'pointer',

  ['&:hover']: {
    color: isSelected ? theme.custom.colors.group1.base : theme.custom.colors.group1.base
  }
}));

interface DestinationsListContainerProps extends ThemeProps {
  displayInColumns: boolean;
}
const DestinationsListContainer: any = styled.ul(({ theme, displayInColumns }: DestinationsListContainerProps) => ({
  flex: 'none',
  padding: 0,
  ...(displayInColumns && { columnCount: 4 }),
  paddingTop: theme.custom.spacing.xLarge,
  paddingBottom: theme.custom.spacing.xLarge,
  listStyleType: 'none',

  [`@media (max-width: ${theme.custom.contentSizes.maxContentWidth}px)`]: {
    marginRight: theme.custom.spacing.medium
  },

  ['li']: {
    maxWidth: displayInColumns ? 'auto' : '30%'
  }
}));

const AdditionalSortingLabel = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  color: theme.custom.colors.group4.light
}));

const DestinationItem = styled.li(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium,

  ['a, a:visited']: {
    color: theme.custom.colors.group4.base,
    display: 'flex',
    alignItems: 'flex-start',
    ...(theme.custom.typography.paragraph as any),
    cursor: 'pointer'
  },

  ['&:hover']: {
    [AdditionalSortingLabel as any]: {
      color: theme.custom.colors.group1.base
    },

    [DestinationItemLabelWrapper as any]: {
      color: theme.custom.colors.group1.base
    }
  }
}));

const DestinationItemLabelWrapper = styled.span(({ theme }: ThemeProps) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  paddingRight: theme.custom.spacing.medium
}));

const FlagIcon: any = styled(CountryFlag)(({ theme }: ThemeProps) => ({
  marginTop: 4,
  marginRight: theme.custom.spacing.xSmall
}));

const ViewAllTripTypeWrapper = styled.span(({ theme }: ThemeProps) => ({
  fontSize: theme.custom.fontSize.medium,
  marginTop: theme.custom.spacing.medium
}));

/* ************************************* *
 *    DestinationsNavContentComponent    *
 * ************************************* */

export const DestinationsNavContentComponent = (props: DestinationsNavContentProps) => {
  const t: any = useI18NextContext();
  const {
    testId,
    selectedOptions: { selectedFilter, selectedSortOption },
    setOptions: { setSelectedFilter, setSelectedSortOption },
    onToggleDesktopNav,
    cmsDestinations
  } = props;

  /* *** LOGIC *** */
  const viewAllLabel = t('trip-types__view--all', { tripType: tripTypeLabels[selectedFilter] });

  const getDestinationNames = (filter: TripTypes) => {
    const destinations: CMSDestinations = getDestinationsByTripType(cmsDestinations, filter);

    // sort by selected sort option
    let fieldName;

    switch (selectedSortOption) {
      case SortOptions.SORT_ALPHABETICAL:
        fieldName = 'title';
        break;
      case SortOptions.SORT_BY_FLIGHT_TIMES:
        fieldName = 'flightTime';
        break;
      default: {
        fieldName = 'title';
      }
    }

    return sortByField(destinations, fieldName, SortDirection.ASC);
  };

  const destinations = getDestinationNames(selectedFilter);

  const getAdditionalSortingLabel = (destination: CMSDestination) => {
    switch (selectedSortOption) {
      case SortOptions.SORT_BY_FLIGHT_TIMES: {
        return (
          <AdditionalSortingLabel data-testid={TestId.destinationsNavContent.additionalSortLabel}>
            {formatFlightTime(parseInt(destination.flightTime))}
          </AdditionalSortingLabel>
        );
      }
      default: {
        return '';
      }
    }
  };

  const handleSetSelectedFilter = (tripType: TripTypes) => {
    setSelectedFilter(tripType);
  };

  /* *** RENDERERS *** */
  const renderFilters = () =>
    getTripTypesFromDestinations({ cmsDestinations, ignoreTripType: TripTypes.HOLIDAYS }).map(
      (tripType: CMSTripType, index: number) => {
        return (
          tripType.tripTypeId !== TripTypes.ALL && (
            <FilterItem
              data-testid={TestId.destinationsNavContent.filterItem}
              key={`${tripType.tripTypeId}-${index}`}
              isSelected={selectedFilter === tripType.tripTypeId}
              onClick={() => handleSetSelectedFilter(tripType.tripTypeId)}
            >
              {t(tripType.title)}
            </FilterItem>
          )
        );
      }
    );

  const renderSortOptions = () => {
    const sortOptions: Array<SortOptions> = [SortOptions.SORT_ALPHABETICAL, SortOptions.SORT_BY_FLIGHT_TIMES];

    return sortOptions.map((sortOption: SortOptions, index: number) => (
      <SortOption
        data-testid={TestId.destinationsNavContent.sortOption}
        key={`${sortOption}-${index}`}
        onClick={() => setSelectedSortOption(sortOption)}
        isSelected={selectedSortOption === sortOption}
      >
        {t(sortOption)}
      </SortOption>
    ));
  };

  const renderSourceDestinationsList = () => {
    const AllTripTypes = [...Object.values(TripTypes)];
    const removeTripTypes = [TripTypes.ALL, TripTypes.HOLIDAYS, TripTypes.MULTI_CENTRE];
    const tourTripTypes = AllTripTypes.filter((value) => !removeTripTypes.includes(value)).sort();
    const tourDestinations = tourTripTypes.map((tripTypes) => getDestinationNames(tripTypes));

    const tripTypePath = (itemIndex: number): TripTypes | undefined => {
      return tourTripTypes.find((index, value) => {
        if (value === itemIndex) {
          return index;
        }
      });
    };

    return tourDestinations.map((items, index) => {
      return (
        <DestinationsListContainer
          key={index}
          data-testid={TestId.destinationsNavContent.destinationsListContainer}
          style={{ display: 'none' }}
        >
          {items.map((subItems, sIndex) => {
            const path: string = subItems.destinationPath.fields.path;
            {
              return (
                <DestinationLink key={`${path}-${sIndex}`} path={path} tripType={tripTypePath(index)}>
                  <>
                    <FlagIcon countryCode={subItems.countryIsoCode} />
                    <DestinationItemLabelWrapper>
                      {subItems.title}
                      {getAdditionalSortingLabel(subItems)}
                    </DestinationItemLabelWrapper>
                  </>
                </DestinationLink>
              );
            }
          })}
        </DestinationsListContainer>
      );
    });
  };

  const renderDestinationsList = () => {
    const destinationItems = destinations.map((item: CMSDestination, index: number) => {
      const path: string = item.destinationPath.fields.path;

      return (
        <DestinationItem
          key={`${path}-${index}`}
          data-testid={TestId.destinationsNavContent.destinationsItem}
          onClick={onToggleDesktopNav}
        >
          <DestinationLink path={path} tripType={selectedFilter}>
            <>
              <FlagIcon countryCode={item.countryIsoCode} />
              <DestinationItemLabelWrapper>
                {item.title}
                {getAdditionalSortingLabel(item)}
              </DestinationItemLabelWrapper>
            </>
          </DestinationLink>
        </DestinationItem>
      );
    });

    return (
      <>
        <DestinationsListContainer
          data-testid={TestId.destinationsNavContent.destinationsListContainer}
          displayInColumns={destinations.length > 4}
        >
          {destinationItems}
          <TripTypesLink tripTypeId={selectedFilter}>
            <ViewAllTripTypeWrapper onClick={onToggleDesktopNav}>{viewAllLabel}</ViewAllTripTypeWrapper>
          </TripTypesLink>
        </DestinationsListContainer>

        {renderSourceDestinationsList()}
      </>
    );
  };

  return (
    <DestinationsContainer data-testid={testId || TestId.destinationsNavContent.main}>
      <FiltersColumn data-testid={TestId.destinationsNavContent.tripTypesColumn}>
        <FiltersHeading data-testid={TestId.destinationsNavContent.tripTypesHeading}>
          {t('nav__filter--heading')}
        </FiltersHeading>
        <FilterItemsWrapper>
          {/* We want Holidays to be hardcoded as this is the default trip type and it should ALWAYS be the first filter on the list */}
          <FilterItem
            data-testid={TestId.destinationsNavContent.filterItem}
            isSelected={selectedFilter === TripTypes.HOLIDAYS}
            onClick={() => setSelectedFilter(TripTypes.HOLIDAYS)}
          >
            {t('common__label--holidays')}
          </FilterItem>
          {renderFilters()}
        </FilterItemsWrapper>
      </FiltersColumn>
      <DestinationsContentContainer data-testid={TestId.destinationsNavContent.destinationsContentContainer}>
        <DestinationsSortBar data-testid={TestId.destinationsNavContent.sortBar}>
          <TripTypesLink tripTypeId={selectedFilter}>
            <StyledButton label={viewAllLabel} size={ButtonSize.SMALL} onClick={onToggleDesktopNav} />
          </TripTypesLink>
          <Sort>
            <SortHeading data-testid={TestId.destinationsNavContent.sortHeading}>
              {t('common__label--sort-by')}
            </SortHeading>
            <SortOptionsContainer data-testid={TestId.destinationsNavContent.sortOptionsContainer}>
              {renderSortOptions()}
            </SortOptionsContainer>
          </Sort>
        </DestinationsSortBar>
        {renderDestinationsList()}
      </DestinationsContentContainer>
    </DestinationsContainer>
  );
};

export const DestinationsNavContent = withTheme(DestinationsNavContentComponent);
